import { get } from 'http';

export default class Config 
{
    public static APP_TITLE = 'Cloud CTF';
    public static LOCAL_STORAGE_VERSION_NUMBER = process.env.LOCAL_STORAGE_VERSION_NUMBER || "1.00";
    public static IS_VACR = new RegExp(/virginia|localhost|vacr/,'ig').test(window.location.href);
    public static IS_USCR = !Config.IS_VACR;

    // Apis
    public static CYBER_RANGE_UI_BASE_URL = 'http://console.vacr.io:8080';
    public static LOGIN_UI_BASE_URL = 'http://login.vacr.io:8078';
    public static CTF_UI_BASE_URL = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/`;
    public static HOMEPAGE_BASE_URL = 'http://host.vacr.io:8079';
    public static AUTHENTICATION_API_BASE_URL = "http://localhost:8081";
    public static ENTITLEMENT_API_BASE_URL = 'http://localhost:8082';
    public static USER_API_BASE_URL = 'http://localhost:8083';
    public static CATALOG_API_BASE_URL = 'http://localhost:8084';
    public static INVITATION_API_BASE_URL = 'http://localhost:8086/';
    public static ORGANIZATION_API_BASE_URL = 'http://localhost:8087/';
    public static SUBSCRIPTION_API_BASE_URL = 'http://localhost:8088/';
    public static CATALOG_FAMILY_SEARCH_API_BASE_URL = 'http://localhost:8092';
    public static CONTENT_API_BASE_URL = 'http://localhost:8095/';
    public static AGREEMENT_API_BASE_URL = 'http://localhost:8093';
    public static FILE_API_BASE_URL = 'http://localhost:8097/';
    public static COMPETITION_API_BASE_URL = 'http://localhost:8100/';
    public static PLAYER_API_BASE_URL = 'http://localhost:8101/';
    public static TEAM_API_BASE_URL = 'http://localhost:8102/';
    public static CHALLENGE_API_BASE_URL = 'http://localhost:8103/';
    public static LIBRARY_API_BASE_URL = 'http://localhost:8104/';
    public static SUBMISSION_API_BASE_URL = 'http://localhost:8105/';
    public static JOB_API_BASE_URL= 'http://localhost:8109/'
    public static SUPPORT_URL = 'https://kb.virginiacyberrange.org';

    //Feature
    public static FEATURE_INVITATION_ENABLED:boolean;

    //Fetch size
    public static DEFAULT_FETCH_SIZE:number = 300;
    public static LIBRARY_FETCH_SIZE:number = 1000;
    public static SUBMISSION_FETCH_SIZE:number = 2000;

    //Misc
    public static DEFAULT_MAX_NUMBER_OF_ATTEMPTS:number = 100;
    public static DEFAULT_MULTIPLE_CHOICE_MAX_NUMBER_OF_ATTEMPTS:number = 1;
    public static UNCATEGORIZED:string = 'Uncategorized';
    public static TEMP_HINT_ID_PREFIX = 'tmp_';
    public static MAX_IMPORT_CHALLENGES_FILE_SIZE_IN_MB: number = 6;
    public static MAX_EXPORT_IMPORT_CHALLENGE_COUNT: number = 50;
    public static MAX_ALLOWED_HEATED_ENVIRONMENTS: number = 1000;

    public static async load(remotePath: string): Promise<void> 
    {
        const json = await Config.getFile(remotePath);
        Config.init(json);
    }

    public static async getFile(url: string): Promise<object> 
    {
        let response = await fetch(url);
        return response.ok ? await response.json() : {};
    }

    public static init(json: object)
    {
        Object.assign(Config, json);
    }
}
