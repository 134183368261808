<template>
  <div>
    <v-row row wrap class="ma-2">
        <v-col cols="11">
            <h2>{{$t('SETTINGS_TITLE')}}</h2>
        </v-col>
          <v-col cols="1">
        </v-col>
    </v-row>
    <v-card :dark="isDark" class="pt-2 pb-2 pl-5 pr-5 elevation-0 alphaBackground" id="settingsCard">
      <v-form v-model="valid" lazy-validation ref="form" id="settings">
        <v-row>
          <v-col cols="12" sm="6" md="5" lg="4" class="mt-5" :class="{ 'mr-5': $vuetify.breakpoint.mdAndUp }">
            <v-row>
              <v-col cols="12" class="pt-0 pb-0">
                <v-text-field v-model="competitionName" :label="$t('SETTINGS_COMPETITION_NAME')" :rules="competitionNameRules" :counter="maxCompetitionName" :disabled="isLoading" id="settingsCompetitionName" />
              </v-col>
              <v-col cols="12" class="pt-0 pb-0">
                <date-time-picker v-model="competitionStartTime" :label="$t('SETTINGS_START')" :dark="isDark" :disabled="isLoading" id="settingsStartAt" />
              </v-col>
              <v-col cols="12" class="pt-0 pb-0">
                <date-time-picker v-model="competitionEndTime" :label="$t('SETTINGS_END')" :dark="isDark" :disabled="isLoading" id="settingsEndAt" />
              </v-col>
              <v-col cols="12" class="pt-0 pb-0">
              </v-col>
              <v-col v-if="canViewAllCompetitions()" cols="12" class="mt-0 pt-0 pb-0">
                <v-text-field v-model="competitionSubdomain" :label="$t('SETTINGS_COMPETITION_SUBDOMAIN')" :rules="competitionSubdomainRules" :counter="maxCompetitionSubdomain" :disabled="isLoading" id="settingsCompetitionSubdomain" />
              </v-col>
              <v-col cols="12">
                <v-tooltip top v-if="!canAllowPublicAccess" data-testing="allow-public-access-tooltip">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on.stop="on" class="floatingIconTooltip">info</v-icon>
                  </template>
                  <span> {{$t("SETTINGS_DISALLOW_PUBLIC_REGISTRATION_TOOLTIP")}} </span>
                </v-tooltip>
                <v-checkbox v-model="allowPublicRegistration" :label='$t("SETTINGS_ALLOW_PUBLIC_REGISTRATION")' :dark="isDark" class="switch mt-0 mb-0 pb-0" :disabled="isLoading || !canAllowPublicAccess" id="settingsAllowPublicRegistration"/>
                <div class="d-flex">
                  <v-checkbox v-model="allowDirectLogin" :label='$t("SETTINGS_ALLOW_DIRECT_LOGIN")' :dark="isDark" class="switch mt-0 mb-0 pb-0 ml-7" :disabled="isLoading || !allowPublicRegistration || !canAllowDirectLogin" id="settingsAllowDirectLogin"/>
                  <v-tooltip top v-if="!canAllowDirectLogin" data-testing="allow-direct-login-tooltip">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" class="pl-1">info</v-icon>
                    </template>
                    <span> {{$t("SETTINGS_ALLOW_DIRECT_LOGIN_TOOLTIP")}} </span>
                  </v-tooltip>
                </div>
                <v-checkbox v-model="allowTeamNameChange" :label='$t("SETTINGS_ALLOW_TEAM_NAME_CHANGED")' :dark="isDark" class="switch mt-0 mb-0 pb-0" :disabled="isLoading" id="settingsAllowTeamNameChange" />                             
                <v-checkbox v-model="allowPlayerNameChange" :label='$t("SETTINGS_ALLOW_PLAYER_NAME_CHANGED")' :dark="isDark" class="switch mt-0 mb-0 pb-0" :disabled="isLoading" id="settingsAllowPlayerNameChange" />
                <v-checkbox v-model="allowTeamMembershipChange" :label='$t("SETTINGS_ALLOW_TEAM_PLAY")' :dark="isDark" class="switch mt-0 mb-0 pb-0" :disabled="isLoading" id="settingsAllowTeamPlay" />
                <v-text-field v-model="maxPlayersPerTeam" :label="$t('SETTINGS_MAX_PLAYERS_PER_TEAM_LABEL')"  type="number" autocomplete="false" :rules="competitionLimitMaxPlayersPerTeamRule" class="mt-2 mb-0 mb-0 ml-8" :disabled="!allowTeamMembershipChange || isLoading"  id="maxPlayersPerTeamTextField"  />   
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" class="show-scoreboard-tooltip">info</v-icon>
                  </template>
                  <span> {{$t("SETTINGS_SHOW_SCOREBOARD_TOOLTIP")}} </span>
                </v-tooltip>
                <v-checkbox v-model="showDashboard" :label='$t("SETTINGS_SHOW_SCOREBOARD")' :dark="isDark" class="shrink switch mt-0 mb-0 pb-0" :disabled="isLoading" id="settingsShowDashboard" />
                <v-checkbox v-model="allowUnregisteredUsersToViewDashboard" :label='$t("SETTINGS_ALLOW_UNREGISTERED_USERS_TO_VIEW_SCOREBOARD")' :dark="isDark" class="switch mt-0 mb-0 pb-0 ml-7" :disabled="isLoading || !showDashboard" id="settingsAllowUnregisteredUsersToViewDashboard" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" md="5" lg="4" :class="{ 'mt-5': $vuetify.breakpoint.smAndUp }">
            <v-row>
              <v-col cols="12" class="pt-0 pb-1">
                <v-text-field v-model="maxPlayers" id="maxPlayersTextField" :disabled="!canViewAllCompetitions() || isLoading" :label="$t('SETTINGS_MAX_PLAYERS_LABEL')" type="number" autocomplete="false" :rules="competitionLimitPositiveIntegerRule">
                  <template v-slot:append-outer>
                    <v-tooltip bottom open-on-hover class="maxPlayersTextFieldTooltip">
                      <template v-slot:activator="{ on, attrs }" >
                        <span v-on="on" v-bind="attrs">
                          <v-icon dense>fa-info-circle</v-icon>
                        </span>
                      </template>
                      <span v-html="$t('SETTINGS_LIMITS_TOOLTIP_TEXT')"/>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pt-0 pb-1">
                <v-text-field v-model="maxTeams" id="maxTeamsTextField" :disabled="!canViewAllCompetitions() || isLoading" :label="$t('SETTINGS_MAX_TEAMS_LABEL')" type="number" autocomplete="false" :rules="competitionLimitPositiveIntegerRule">
                  <template v-slot:append-outer>
                    <v-tooltip bottom open-on-hover class="maxTeamsTextFieldTooltip">
                      <template v-slot:activator="{ on, attrs }" >
                        <span v-on="on" v-bind="attrs">
                          <v-icon dense>fa-info-circle</v-icon>
                        </span>
                      </template>
                      <span v-html="$t('SETTINGS_LIMITS_TOOLTIP_TEXT')"/>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pt-0 pb-1">
                  <v-text-field v-model="maxChallenges" id="maxChallengesTextField" :disabled="!canViewAllCompetitions() || isLoading" :label="$t('SETTINGS_MAX_CHALLENGES_LABEL')" type="number" autocomplete="false" :rules="competitionLimitPositiveIntegerRule">
                    <template v-slot:append-outer>
                      <v-tooltip bottom open-on-hover class="maxChallengesTextFieldTooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-on="on" v-bind="attrs">
                            <v-icon dense>fa-info-circle</v-icon>
                          </span>
                        </template>
                        <span v-html="$t('SETTINGS_LIMITS_TOOLTIP_TEXT')"/>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pt-0 pb-1">
                <v-text-field v-model="maxFileStorageInMb" id="maxFileStorageInMbTextField" :disabled="!canViewAllCompetitions() || isLoading" :label="$t('SETTINGS_MAX_FILE_STORAGE_LABEL')" type="number" autocomplete="false" :rules="competitionLimitPositiveIntegerRule">
                  <template v-slot:append-outer>
                    <v-tooltip bottom open-on-hover class="maxFileStorageInMbTextFieldTooltip">
                      <template v-slot:activator="{ on, attrs }" >
                        <span v-on="on" v-bind="attrs">
                          <v-icon dense>fa-info-circle</v-icon>
                        </span>
                      </template>
                      <span v-html="$t('SETTINGS_LIMITS_TOOLTIP_TEXT')"/>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pt-0 pb-1">
                <v-text-field v-model="maxEnvironments" id="maxEnvironmentsTextField" :disabled="!canViewAllCompetitions() || isLoading" :label="$t('SETTINGS_MAX_ENVIRONMENTS_LABEL')" type="number" autocomplete="false" :rules="competitionLimitPositiveIntegerRule">
                  <template v-slot:append-outer>
                    <v-tooltip bottom open-on-hover class="maxEnvironmentsTextFieldTooltip">
                      <template v-slot:activator="{ on, attrs }" >
                        <span v-on="on" v-bind="attrs">
                          <v-icon dense>fa-info-circle</v-icon>
                        </span>
                      </template>
                      <span v-html="$t('SETTINGS_LIMITS_TOOLTIP_TEXT')"/>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pt-0 pb-1">
                <v-text-field v-model="maxEnvironmentsPerChallenge" id="maxEnvironmentsPerChallengeTextField" :disabled="!canViewAllCompetitions() || isLoading" :label="$t('SETTINGS_MAX_ENVIRONMENTS_PER_CHALLENGE_LABEL')" type="number" autocomplete="false" :rules="competitionLimitPositiveIntegerRule">
                  <template v-slot:append-outer>
                    <v-tooltip bottom open-on-hover class="maxEnvironmentsPerChallengeTextFieldTooltip">
                      <template v-slot:activator="{ on, attrs }" >
                        <span v-on="on" v-bind="attrs">
                          <v-icon dense>fa-info-circle</v-icon>
                        </span>
                      </template>
                      <span v-html="$t('SETTINGS_LIMITS_TOOLTIP_TEXT')"/>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
              <v-col v-if="canUpdateCompetitionHeatedEnvironments" cols="12" class="pt-0 pb-1">
                <v-text-field v-model="numberOfHeatedEnvironments" id="numberOfHeatedEnvironments" :disabled="isLoading" :label="$t('SETTINGS_NUMBER_OF_HEATED_ENVIRONMENTS_LABEL')" type="number" autocomplete="false" :rules="numberOfHeatedEnvironmentsRule">
                  <template v-slot:append-outer>
                    <v-tooltip bottom open-on-hover class="numberOfHeatedEnvironmentsTooltip">
                      <template v-slot:activator="{ on, attrs }" >
                        <span v-on="on" v-bind="attrs">
                          <v-icon dense>fa-info-circle</v-icon>
                        </span>
                      </template>
                      <span v-html="$t('SETTINGS_NUMBER_OF_HEATED_ENVIRONMENTS_TOOLTIP')"/>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0">
                <sorted-select v-model="themeId" :items="themes" :label="$t('SETTINGS_THEME')" :disabled="isLoading" id="settingsTheme" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-btn id="settingsConfirmButton" @click="confirm" :disabled="isConfirmDisabled" :dark="isConfirmDisabled ? isDark : !isConfirmLight" :light="isConfirmDisabled ? !isDark : isConfirmLight" class="confirmButton elevation-0" color="confirm">{{$t('SETTINGS_CONFIRM')}}</v-btn>
            <v-btn id="settingsResetButton" @click="reset" text :dark="isDark" :disabled="!changedAndValid || isLoading">{{$t('SETTINGS_RESET')}}</v-btn>
          </v-col>
        </v-row>
      </v-form>
      <confirmation-dialog v-model="showSubdomainRedirectDialog" v-if="showSubdomainRedirectDialog" :title="$t('SETTINGS_SUBDOMAIN_CHANGED_DIALOG_TITLE')" :confirm="$t('SETTINGS_REDIRECT_DIALOG_CONFIRM')" @confirm="redirectToNewSubdomain" :cancel="false" id="subdomainRedirectDialog">
        {{$t('SETTINGS_SUBDOMAIN_CHANGED_DIALOG_MESSAGE')}}
      </confirmation-dialog>
      <confirmation-dialog v-model="showAskForRefreshDialog" v-if="showAskForRefreshDialog" :title="$t('SETTINGS_RELOAD_DIALOG_TITLE')" :confirm="$t('SETTINGS_RELOAD_DIALOG_CONFIRM')" :cancel="$t('SETTINGS_RELOAD_DIALOG_CANCEL')" @confirm="reload" id="settingsReloadDialog">
        {{$t('SETTINGS_RELOAD_DIALOG_MESSAGE')}}
      </confirmation-dialog>
    </v-card>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import Rule from '../../../validations/Rule';
import { ICompetition, ICompetitionApiClient, ICompetitionSettings, CustomCompetitionLimits, ICustomCompetitionLimits } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { DefaultTheme } from '../../../themes/themes/defaultTheme';
import ITheme from '@/interfaces/iTheme';
import TitleStrings from '@/entities/strings/definitions/titleStrings';
import Config from '@/config';
import Route from '@/interfaces/route';
import { resolve } from 'url';
import { useThemeStore } from '@stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useAuthenticationStore } from '@stores/authenticationStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useSubscriptionStore } from '@stores/subscriptionStore';
import { useAuthorizationStore } from '@stores/authorizationStore';

@Component({ metaInfo: { title: TitleStrings.en.TITLE_MANAGE_SETTINGS }})
export default class Settings extends Vue
{
    // TODO: Change this to composition api
    get isDark():boolean
    {
      return useThemeStore().isDark;
    }
    get availableThemes():ITheme[]
    {
      return useThemeStore().availableThemes;
    }
    get isConfirmLight(): boolean
    {
      return useThemeStore().isConfirmLight;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get competitionApiClient(): ICompetitionApiClient
    {
        return useApiClientStore().competitionApiClient;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    get canAllowPublicAccess(): boolean
    {
        return useSubscriptionStore().canAllowPublicAccess;
    }
    get canAllowDirectLogin(): boolean
    {
        return useSubscriptionStore().canAllowDirectLogin;
    }
    get canUpdateCompetitionHeatedEnvironments(): boolean
    {
      return useAuthorizationStore().canUpdateCompetitionHeatedEnvironments(this.competition.id, this.competition.organizationId);
    }
    canViewAllCompetitions(): boolean
    {
        return useAuthorizationStore().canViewAllCompetitions();
    }
    // END TODO

    get isConfirmDisabled()
    {
      return !this.changedAndValid || this.isLoading;
    }

    valid = false;
    themeId = DefaultTheme.Id;
    emailVerificationRequired = false;

    private_allowPublicRegistration_ = false; // vue doesn't proxy values starting with _, so private_ was chosen instead
    get allowPublicRegistration()
    {
        return !!(this.private_allowPublicRegistration_ && this.canAllowPublicAccess)
    }
    set allowPublicRegistration(value)
    {
        this.private_allowPublicRegistration_ = value;
    }

    private_allowDirectLogin_ = false; // vue doesn't proxy values starting with _, so private_ was chosen instead
    get allowDirectLogin()
    {
        return !!(this.private_allowDirectLogin_ && this.canAllowDirectLogin && this.allowPublicRegistration)
    }
    set allowDirectLogin(value)
    {
        this.private_allowDirectLogin_ = value;
    }

    // custom limits
    competitionCustomLimits:Partial<ICustomCompetitionLimits> = {};
    get competitionLimitMaxPlayersPerTeamRule()
    {
        let maxPlayersPerTeamRule = this.canViewAllCompetitions()
                ? ( v: string )=> Rule.maxValue(v, Number(this.maxPlayers) || 25)
                : ( v: string )=> Rule.maxValue(v, this.competitionCustomLimits?.maxPlayersPerTeam?.max || 25)
        return [Rule.require, ( v: string )=> Rule.minValue(v, 2), maxPlayersPerTeamRule];
    }

    get numberOfHeatedEnvironmentsRule()
    {
        let maxValue = Math.min(Number(this.maxPlayers), Config.MAX_ALLOWED_HEATED_ENVIRONMENTS);

        return [Rule.require, (v: string) => Rule.minValue(v, 0), (v: string) => Rule.maxValue(v, maxValue)];
    }

    allowTeamNameChange = false;
    allowUnregisteredUsersToViewDashboard = false;
    allowTeamMembershipChange = false;
    allowPlayerNameChange = false;
    showDashboard = true;

    showAskForRefreshDialog = false;
    showSubdomainRedirectDialog = false;

    competitionRecipeParameters = [];
    competitionLimitPositiveIntegerRule = [ Rule.require, ( v: string )=> Rule.minValue(v, 0) ];
    maxFileStorageInMb: number | string = 0;
    maxTeams: number | string = 0;
    maxPlayers: number | string = 0;
    maxPlayersPerTeam: number | string = 0;
    maxChallenges: number | string = 0;
    maxEnvironments: number | string = 0;
    maxEnvironmentsPerChallenge: number | string = 0;
    numberOfHeatedEnvironments: number | string = 0;
    competitionName = '';
    competitionStartTime = '';
    competitionEndTime = '';
    competitionSubdomain = '';
    maxCompetitionName = 256;
    maxCompetitionSubdomain = 63;
    minCompetitionSubdomain = 5;

    get themes()
    {
        return this.availableThemes.map(t => ({text: t.name, value:t.id}));
    }

    get changedAndValid(): boolean
    {
        if(this.$refs.form && !(<any>this.$refs.form).validate())
        {
          return false;
        }
        return this.competitionName !== this.competition.name ||
               this.competitionSubdomain !== this.competition.subdomain ||
               this.competitionStartTime !== (this.competition.settings?.startTime || '') ||
               this.competitionEndTime !== (this.competition.settings?.endTime || '') ||
               this.emailVerificationRequired !== (this.competition.settings?.requireEmailVerification || false) ||
               this.allowPublicRegistration !== (this.competition.settings?.allowPublicRegistration || false) ||
               this.allowDirectLogin !== (this.competition.settings?.allowDirectLogin || false ) ||
               this.allowTeamNameChange !== (this.competition.settings?.allowTeamNameChange || false) ||
               this.allowTeamMembershipChange !== (this.competition.settings?.allowTeamMembershipChange || false) ||
               this.allowPlayerNameChange !== (this.competition.settings?.allowPlayerNameChange || false) ||
               this.allowUnregisteredUsersToViewDashboard !== (this.competition.settings?.allowUnregisteredUsersToViewDashboard || false) ||
               this.showDashboard !== !(this.competition.settings?.hideDashboard || false) ||
               this.themeId !== (this.competition.settings?.themeId || DefaultTheme.Id) ||
               this.restrictedLimitsModified ||
               this.unrestrictedLimitsModified ||
               Number(this.numberOfHeatedEnvironments) !== (this.competition.settings?.numberOfHeatedEnvironments || 0)
    }

    competitionNameRules = [Rule.require, (v)=>Rule.maxLength(v, this.maxCompetitionName)]
    competitionSubdomainRules = [Rule.require, (v)=>Rule.maxLength(v, this.maxCompetitionSubdomain), (v)=>Rule.minLength(v,this.minCompetitionSubdomain), (v)=>Rule.hyphenatedLowerCaseAlphanumeric(v), (v)=>Rule.doesNotIncludeInvalidSubstrings(v,['www'])]

    mounted()
    {
        this.reset();
    }

    get restrictedLimitsModified(): boolean
    {
      return (Number(this.maxFileStorageInMb) !== Number(this.competition.limits?.maxFileStorageInMb) ||
            Number(this.maxTeams) !== Number(this.competition.limits?.maxTeams) ||
            Number(this.maxPlayers) !== Number(this.competition.limits?.maxPlayers) ||
            Number(this.maxChallenges) !== Number(this.competition.limits?.maxChallenges) ||
            Number(this.maxEnvironments) !== Number(this.competition.limits?.maxEnvironments) ||
            Number(this.maxEnvironmentsPerChallenge) !== Number(this.competition.limits?.maxEnvironmentsPerChallenge))
    }

    get unrestrictedLimitsModified(): boolean
    {
        return Number(this.maxPlayersPerTeam) !== Number(this.competition.limits?.maxPlayersPerTeam);
    }

    reset()
    {
        useSubscriptionStore().fetchSubscribedProducts();
        this.competitionName = this.competition.name;
        this.competitionSubdomain = this.competition.subdomain;
        this.competitionRecipeParameters = this.competition.recipeParameters || [];
        this.competitionStartTime = this.competition.settings?.startTime ? this.competition.settings.startTime : '';
        this.competitionEndTime = this.competition.settings?.endTime ? this.competition.settings.endTime : '';
        this.emailVerificationRequired = this.competition.settings?.requireEmailVerification || false;
        this.allowPublicRegistration = this.competition.settings?.allowPublicRegistration || false;
        this.allowDirectLogin = this.competition.settings?.allowDirectLogin || false;
        this.allowTeamNameChange = this.competition.settings?.allowTeamNameChange || false;
        this.allowTeamMembershipChange = this.competition.settings?.allowTeamMembershipChange || false;
        this.allowPlayerNameChange = this.competition.settings?.allowPlayerNameChange || false;
        this.allowUnregisteredUsersToViewDashboard = this.competition.settings?.allowUnregisteredUsersToViewDashboard || false;
        this.showDashboard = !(this.competition.settings?.hideDashboard || false);
        this.themeId = this.competition.settings?.themeId || DefaultTheme.Id;
        this.maxFileStorageInMb = this.competition?.limits?.maxFileStorageInMb || 0;
        this.maxTeams = this.competition?.limits?.maxTeams || 0;
        this.maxPlayers = this.competition?.limits?.maxPlayers || 0;
        this.maxPlayersPerTeam = this.competition?.limits?.maxPlayersPerTeam || 0;
        this.maxChallenges = this.competition?.limits?.maxChallenges || 0;
        this.maxEnvironments = this.competition?.limits?.maxEnvironments || 0;
        this.maxEnvironmentsPerChallenge = this.competition?.limits?.maxEnvironmentsPerChallenge || 0;
        this.numberOfHeatedEnvironments = this.competition.settings?.numberOfHeatedEnvironments || 0;
        this.refreshCustomLimits();
    }

    refreshCustomLimits()
    {
        useCompetitionStore().fetchCurrentCompetitionLimits().then(limits=>{
            this.competitionCustomLimits = limits;
        });
    }

    reload()
    {
        window.location.reload();
    }

    async redirectToNewSubdomain()
    { 
      let ssoToken = await useAuthenticationStore().getSsoToken();
      let newBaseUrl = `${Config.CTF_UI_BASE_URL.replace(location.hostname.split('.')[0],this.competitionSubdomain)}`
      window.location.replace(`${resolve(newBaseUrl,Route.Settings.path)}?s=${ssoToken}`)
    }

    async confirm()
    {
        if((<any>this.$refs.form).validate())
        {
            const newMaxPlayersPerTeamLimitsPayload = Number(this.maxPlayersPerTeam) > (this.competitionCustomLimits?.maxPlayersPerTeam?.max || 0)
                ? { current: Number(this.maxPlayersPerTeam), max: Number(this.maxPlayersPerTeam)}
                : { current: Number(this.maxPlayersPerTeam)}

            if(this.restrictedLimitsModified)
            {
                await this.competitionApiClient.updateCompetitionLimits(this.competition.id, new CustomCompetitionLimits({
                    maxFileStorageInMb: { current: Number(this.maxFileStorageInMb), max: Number(this.maxFileStorageInMb) },
                    maxTeams: { current: Number(this.maxTeams), max: Number(this.maxTeams) },
                    maxPlayers: { current: Number(this.maxPlayers), max: Number(this.maxPlayers) },
                    maxChallenges: { current: Number(this.maxChallenges), max: Number(this.maxChallenges) },
                    maxPlayersPerTeam: this.unrestrictedLimitsModified ? newMaxPlayersPerTeamLimitsPayload : undefined,
                    maxEnvironments: { current: Number(this.maxEnvironments), max: Number(this.maxEnvironments) },
                    maxEnvironmentsPerChallenge: { current: Number(this.maxEnvironmentsPerChallenge), max: Number(this.maxEnvironmentsPerChallenge) },
                }));
            }
            else if(this.unrestrictedLimitsModified)
            {
                await this.competitionApiClient.updateCompetitionLimits(this.competition.id, new CustomCompetitionLimits({
                    maxPlayersPerTeam: newMaxPlayersPerTeamLimitsPayload
                }));
            }

            await this.competitionApiClient.update(this.competition.id, <ICompetition>{
                name: this.competitionName,
                subdomain: this.competitionSubdomain,
                settings: <ICompetitionSettings>{
                    startTime: this.competitionStartTime,
                    endTime: this.competitionEndTime,
                    themeId: this.themeId,
                    requireEmailVerification: this.emailVerificationRequired,
                    allowPublicRegistration: this.allowPublicRegistration,
                    allowDirectLogin: this.allowDirectLogin,
                    allowPlayerNameChange: this.allowPlayerNameChange,
                    allowTeamNameChange: this.allowTeamNameChange,
                    allowTeamMembershipChange: this.allowTeamMembershipChange,
                    allowUnregisteredUsersToViewDashboard: this.allowUnregisteredUsersToViewDashboard,
                    hideDashboard: !this.showDashboard,
                    numberOfHeatedEnvironments: Number(this.numberOfHeatedEnvironments)
                }
            });

            if(this.competitionSubdomain !== this.competition.subdomain)
            {
                this.showSubdomainRedirectDialog = true;
            }
            else
            {
              if(this.themeId !== this.competition.settings?.themeId &&
                !(this.themeId === DefaultTheme.Id && this.competition.settings?.themeId === undefined))
              {
                this.showAskForRefreshDialog = true;
              }
            }
            await useCompetitionStore().refreshCurrentCompetition();
        }
    }
}
</script>

<style>
#settings .switch .v-messages /* reduces spacing below check boxes */
{
  display: none !important;
}
#settings .v-text-field
{
  padding-top: 0;
}

#settings .v-input__append-outer {
  pointer-events: all !important;
}
</style>